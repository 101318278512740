'use client';

import * as React from 'react';
import {observer} from 'mobx-react';
import {useRouter} from 'next/navigation';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {ROUTES} from '@/constants/routes';

export default observer(function NotFoundPage() {
  const {t} = useTranslation();

  const {push} = useRouter();

  const handleClickToMain = React.useCallback(() => {
    push(ROUTES.ROOT);
  }, []);

  return (
    <Box width="100%" my={{default: 0, tablet: 30}} p={20}>
      <Box
        width={{default: '100%', tablet: 400}}
        alignSelf="center"
        alignItems="center"
      >
        <Box mb={20} width={{default: '100%', tablet: 400}}>
          <Illustration name="illustration-12" />
        </Box>
        <Heading mb={20}>{t('surface.not_found.title')}</Heading>
        <Text mb={20} textAlign="center">
          {t('surface.not_found.description')}
        </Text>
        <Button size="large" onPress={handleClickToMain}>
          {t('surface.not_found.go_to_main_page')}
        </Button>
      </Box>
    </Box>
  );
});
