// This is a map of all the routes used in the app. It should correspond to the file structure.
export enum ROUTES {
  ROOT = '/',
  WELCOME_FORM = '/welcome-form',
  MAIN_FORM = '/main-form',

  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  RESTORE_PASSWORD = '/restore-password',
  NEW_PASSWORD = '/new-password',
  VERIFY_EMAIL = '/verify-email', // on sign-up
  DEVICE_CONFIRM = '/device-confirm', // on sign-in, if applicable
  SIGN_IN_CONFIRM = '/sign-in-confirm', // 2fa

  PLEASE_VERIFY = '/please-verify', // sumsub
  VERIFICATION_IDENTITY = '/verification-identity', // sumsub
  VERIFICATION_ADDRESS = '/verification-address', // sumsub
  FORM_A = '/form-a',
  DISCLOSURE = '/disclosure',

  PROFILE = '/profile',
  HISTORY = '/profile/history',
  TRANSACTION = '/profile/history/transaction',
  DELETE_ACCOUNT = '/profile/delete-account',

  DEPOSIT_SUCCESS = '/deposit-success',
  DEPOSIT_FAIL = '/deposit-fail',

  AGREEMENT = '/agreement',
  BANK_WIRE_DEPOSIT = '/bank-wire-deposit',
  BANK_WIRE_DEPOSIT_ALTERNATIVE = '/bank-wire-deposit/alternative-requisites',

  NOT_FOUND = '/404',
}
